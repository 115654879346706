<template>
  <v-autocomplete
    v-model="location"
    :items="$store.state.selects.locations[type]"
    :loading="loading"
    :label="label"
    :multiple="multiple"
    v-bind="$attrs"
    dense
    :clearable="clearable"
    :small-chips="smallChips"
    menu-props="offsetY"
    @change="$emit('update:value', location)"
  >
    <template
      v-if="icon"
      #prepend-inner
    >
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        {{ icon }}
      </v-icon>
    </template>
    <template
      v-if="!flag"
      #selection="{item}"
    >
      {{ item.value }}
    </template>
    <template
      v-if="flag"
      #item="{item}"
    >
      <v-list-item-icon>
        <CountryFlag
          :country="item.value"
          class="mt-n2"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    components: {
      CountryFlag: () => import('vue-country-flag'),
    },
    props: {
      value: {
        type: [Array, String],
        default: () => [],
      },
      type: {
        type: String,
        default: 'states',
      },
      label: {
        type: String,
        required: true,
      },
      icon: {
        type: [String, Boolean],
        default: 'fal fa-map-marker-alt',
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      smallChips: {
        type: Boolean,
        default: true,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      flag: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        loading: false,
        location: this.value,
        locations: [],
      }
    },
    watch: {
      value () {
        this.location = this.value
      },
    },
    created () {
      if (this.$store.state.selects.locations[this.type]?.length === 0) {
        this.fetchLocations()
      }
    },
    methods: {
      fetchLocations () {
        this.loading = true
        const promise = this.axios.get(`data/${this.type}`, {
          params:
            {
              field: 'subclass',
            },
        })
        return promise.then((response) => {
          this.$store.state.selects.locations[this.type] = response.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
